.monitor-carousel {
    display: flex;
    gap: var(--space-4);
    width: 100%;
    height: 300px;
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    padding: var(--space-4);
    cursor: grab;
    -webkit-overflow-scrolling: touch; /* For smooth scrolling on iOS */
    
    /* Prevent text selection when scrolling */
    user-select: none; /* Prevent text selection */
    -webkit-user-select: none; /* For Safari */
    -moz-user-select: none; /* For Firefox */
}

.monitor-carousel:active {
    cursor: grabbing;
}

/* Hide scrollbar but keep functionality */
.monitor-carousel {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
}

.monitor-carousel::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}

.carousel-monitor {
    min-width: var(--monitor-width);
    height: 100%;
    scroll-snap-align: center;
}

/* Custom Properties */
:root {
    --monitor-width: calc((100% - var(--space-4) * 2) / 3);
}

@media (max-width: var(--breakpoint-lg)) {
    :root {
        --monitor-width: calc((100% - var(--space-4)) / 2);
    }
}

@media (max-width: var(--breakpoint-md)) {
    :root {
        --monitor-width: 85%;
    }
    
    .monitor-carousel {
        padding: var(--space-3);
        gap: var(--space-3);
    }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
    .monitor-carousel {
        scroll-behavior: auto;
    }
}
