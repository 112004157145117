/* Design System */
:root {
  /* Color Palette - Using modern color spaces for better vibrancy */
  --color-neon: oklch(85% 0.3 160);        /* Bright cyber green */
  --color-neon-dim: oklch(65% 0.2 160);    /* Dimmed cyber green */
  --color-dark: oklch(2% 0 0);             /* Deep black */
  --color-darker: oklch(1% 0 0);           /* Darker black */
  --color-light: oklch(98% 0 0);           /* Off white */
  --color-glass: oklch(50% 0 0 / 0.1);     /* Glass effect base */
  
  /* Functional Colors */
  --color-bg: var(--color-dark);
  --color-bg-elevated: var(--color-darker);
  --color-text: var(--color-light);
  --color-primary: var(--color-neon);
  --color-secondary: var(--color-neon-dim);
  
  /* Typography */
  --font-main: 'Roboto Mono', monospace;
  --font-size-xs: 0.75rem;    /* 12px */
  --font-size-sm: 0.875rem;   /* 14px */
  --font-size-md: 1rem;       /* 16px */
  --font-size-lg: 1.125rem;   /* 18px */
  --font-size-xl: 1.25rem;    /* 20px */
  --font-size-2xl: 1.5rem;    /* 24px */
  --font-size-3xl: 2rem;      /* 32px */
  
  /* Spacing Scale */
  --space-unit: 0.25rem;
  --space-1: calc(var(--space-unit) * 1);  /*  4px */
  --space-2: calc(var(--space-unit) * 2);  /*  8px */
  --space-3: calc(var(--space-unit) * 4);  /* 16px */
  --space-4: calc(var(--space-unit) * 6);  /* 24px */
  --space-5: calc(var(--space-unit) * 8);  /* 32px */
  
  /* Effects */
  --glow-sm: 0 0 5px;
  --glow-md: 0 0 10px;
  --glow-lg: 0 0 20px;
  
  /* Z-index Scale */
  --z-base: 1;
  --z-above: 10;
  --z-dropdown: 100;
  --z-sticky: 200;
  --z-modal-backdrop: 300;
  --z-modal: 400;
  --z-toast: 500;
  --z-tooltip: 600;
  
  /* Transitions */
  --transition-fast: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  --transition-normal: 300ms cubic-bezier(0.4, 0, 0.2, 1);
  
  /* Border Radius */
  --radius-sm: 0.25rem;
  --radius-md: 0.5rem;
  --radius-lg: 1rem;
  --radius-full: 9999px;
  
  /* Breakpoints */
  --breakpoint-sm: 640px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1024px;
  --breakpoint-xl: 1280px;
  
  /* System Settings */
  color-scheme: dark;
  accent-color: var(--color-primary);
}

/* Modern Reset */
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  font-size: 16px;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

body {
  min-height: 100%;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: var(--font-main);
  background-color: var(--color-bg);
  color: var(--color-text);
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

input, button, textarea, select {
  font: inherit;
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  *, *::before, *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* Hide scrollbar but keep functionality */
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
