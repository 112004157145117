.avatar {
    width: calc(var(--space-5) * 2.5);
    height: calc(var(--space-5) * 2.5);
    border-radius: var(--radius-full);
    border: 2px solid var(--color-primary);
    transition: all var(--transition-normal);
    position: relative;
    overflow: hidden;
}

.avatar::after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
        45deg,
        transparent 40%,
        var(--color-primary) 45%,
        var(--color-primary) 55%,
        transparent 60%
    );
    opacity: 0;
    transition: opacity var(--transition-normal);
}

.avatar:hover {
    transform: scale(1.1);
    box-shadow: var(--glow-md) var(--color-primary);
}

.avatar:hover::after {
    opacity: 0.3;
    animation: scan 2s linear infinite;
}

@keyframes scan {
    0% {
        transform: translateX(-100%) translateY(-100%);
    }
    100% {
        transform: translateX(100%) translateY(100%);
    }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
    .avatar:hover {
        transform: none;
    }
    
    .avatar:hover::after {
        animation: none;
    }
}
