.command-center {
  background-color: var(--color-bg);
  color: var(--color-text);
  font-family: var(--font-main);
  min-height: 100dvh;
  padding: var(--space-4);
  position: relative;
  overflow: hidden;
}

.title-container {
  text-align: center;
  margin-bottom: var(--space-5);
}

.hacker-title {
  font-size: var(--font-size-3xl);
  color: var(--color-primary);
  padding: var(--space-2);
  display: inline-block;
  white-space: nowrap;
  letter-spacing: 0.1em;
  transition: all var(--transition-normal);
}

.hacker-title.glow {
  color: var(--color-bg);
  text-shadow: 
    var(--glow-sm) var(--color-primary),
    var(--glow-md) var(--color-primary),
    var(--glow-lg) var(--color-primary);
}

.cursor {
  animation: blink var(--transition-normal) infinite;
}

.army-info {
  background-color: var(--color-glass);
  border: 2px solid var(--color-primary);
  border-radius: var(--radius-lg);
  padding: var(--space-4);
  width: min(80%, 600px);
  margin: 0 auto;
  box-shadow: var(--glow-sm) var(--color-primary);
  backdrop-filter: blur(8px);
}

.army-info h2 {
  color: var(--color-primary);
  font-size: var(--font-size-xl);
  margin-bottom: var(--space-3);
}

.army-info p {
  font-size: var(--font-size-lg);
  margin-bottom: var(--space-2);
}

.army-info ul {
  list-style: none;
  padding-left: var(--space-3);
}

.army-info li {
  margin-bottom: var(--space-2);
  font-size: var(--font-size-md);
}

.back-button {
  display: block;
  width: 200px;
  margin: var(--space-5) auto 0;
  padding: var(--space-2) var(--space-3);
  background-color: var(--color-primary);
  color: var(--color-bg);
  text-align: center;
  text-decoration: none;
  border-radius: var(--radius-md);
  font-weight: bold;
  transition: all var(--transition-normal);
}

.back-button:hover {
  background-color: var(--color-bg);
  color: var(--color-primary);
  box-shadow: 
    var(--glow-sm) var(--color-primary),
    var(--glow-md) var(--color-primary);
}

@keyframes blink {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

@media (max-width: var(--breakpoint-md)) {
  .command-center {
    padding: var(--space-3);
  }

  .hacker-title {
    font-size: var(--font-size-2xl);
  }

  .army-info {
    width: 95%;
    padding: var(--space-3);
  }
}
