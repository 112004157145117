.bottom-icons {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    background-color: var(--color-bg-elevated);
    border-top: 1px solid var(--color-primary);
    padding: var(--space-2);
    z-index: var(--z-sticky);
    backdrop-filter: blur(8px);
}

.icon-button {
    background: none;
    border: none;
    color: var(--color-text);
    font-size: var(--font-size-lg);
    padding: var(--space-2) var(--space-3);
    cursor: pointer;
    transition: all var(--transition-normal);
    border-radius: var(--radius-md);
    position: relative;
}

.icon-button:hover {
    color: var(--color-primary);
    text-shadow: var(--glow-sm) var(--color-primary);
}

.icon-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    inset: 0;
    background-color: oklch(0 0 0 / 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: var(--z-modal);
    backdrop-filter: blur(4px);
}

.modal-content {
    background-color: var(--color-bg-elevated);
    border: 2px solid var(--color-primary);
    border-radius: var(--radius-lg);
    padding: var(--space-5);
    width: min(90%, 1200px);
    position: relative;
    animation: modalEnter var(--transition-normal) ease-out;
}

.close-button {
    position: absolute;
    top: var(--space-2);
    right: var(--space-2);
    background: none;
    border: none;
    color: var(--color-text);
    font-size: var(--font-size-xl);
    cursor: pointer;
    padding: var(--space-2);
    transition: all var(--transition-fast);
}

.close-button:hover {
    color: var(--color-primary);
    transform: scale(1.1);
}

.modal-content h2 {
    color: var(--color-primary);
    font-size: var(--font-size-2xl);
    margin-bottom: var(--space-4);
    text-align: center;
}

.bot-input {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--space-3);
    margin-bottom: var(--space-4);
}

.bot-input input {
    width: 120px;
    background-color: var(--color-bg);
    border: 1px solid var(--color-primary);
    color: var(--color-text);
    font-size: var(--font-size-xl);
    padding: var(--space-2) var(--space-3);
    border-radius: var(--radius-md);
    text-align: center;
}

.bot-input button {
    background: none;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    font-size: var(--font-size-xl);
    padding: var(--space-2) var(--space-3);
    cursor: pointer;
    border-radius: var(--radius-md);
    transition: all var(--transition-normal);
}

.bot-input button:hover:not(:disabled) {
    background-color: var(--color-primary);
    color: var(--color-bg);
    box-shadow: var(--glow-sm) var(--color-primary);
}

.bot-info {
    text-align: center;
    margin-bottom: var(--space-4);
}

.bot-info p {
    font-size: var(--font-size-lg);
    margin-bottom: var(--space-2);
    color: var(--color-text);
}

.build-button-container {
    display: flex;
    justify-content: center;
}

.build-button-container button {
    background-color: var(--color-primary);
    color: var(--color-bg);
    border: none;
    font-size: var(--font-size-lg);
    padding: var(--space-3) var(--space-4);
    cursor: pointer;
    border-radius: var(--radius-md);
    transition: all var(--transition-normal);
}

.build-button-container button:hover:not(:disabled) {
    transform: scale(1.05);
    box-shadow: var(--glow-sm) var(--color-primary);
}

.build-button-container button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

@keyframes modalEnter {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@media (max-width: var(--breakpoint-md)) {
    .modal-content {
        width: 95%;
        padding: var(--space-4);
    }

    .bot-input {
        flex-direction: column;
    }

    .bot-input input {
        width: 100%;
    }
}
