.HomePage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100dvh;
    position: relative;
    background-color: var(--color-bg);
}

/* Enhanced matrix effect */
.HomePage::before {
    content: '';
    position: fixed;
    inset: 0;
    background: 
        linear-gradient(
            to bottom,
            transparent 0%,
            var(--color-bg) 95%
        ),
        repeating-linear-gradient(
            90deg,
            var(--color-glass) 0,
            var(--color-glass) var(--space-4),
            transparent var(--space-4),
            transparent var(--space-5)
        );
    opacity: 0.4;
    pointer-events: none;
    z-index: var(--z-base);
}

/* Ambient glow effect */
.HomePage::after {
    content: '';
    position: fixed;
    inset: 0;
    background: radial-gradient(
        circle at 50% 0%,
        var(--color-primary) 0%,
        transparent 75%
    );
    opacity: 0.05;
    pointer-events: none;
    z-index: var(--z-base);
}

.workspace {
    display: flex;
    gap: var(--space-4);
    justify-content: center;
    align-items: center;
    width: min(80%, var(--breakpoint-xl));
    height: 50dvh;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    position: relative;
    scrollbar-width: none;
}

.workspace::-webkit-scrollbar {
    display: none;
}

/* Responsive design */
@media (max-width: var(--breakpoint-md)) {
    .workspace {
        width: 100%;
        gap: var(--space-3);
    }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
    .HomePage::before,
    .HomePage::after {
        opacity: 0.2;
    }
}
