/* App.css */
.App {
  min-height: 100dvh;
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg);
  color: var(--color-text);
}

/* Utility class for page transitions */
.page-transition {
  transition: opacity var(--transition-normal);
}

/* Container for main content */
.main-container {
  flex: 1;
  width: 100%;
  max-width: var(--breakpoint-xl);
  margin: 0 auto;
  padding: var(--space-3);
}

@media (max-width: var(--breakpoint-md)) {
  .main-container {
    padding: var(--space-2);
  }
}

/* Hide spinner arrows for number inputs */
input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none; /* For Chrome, Safari, and Opera */
    margin: 0; /* Remove margin */
}

input[type="number"] {
    appearance: none; /* Remove spinner in all browsers */
}