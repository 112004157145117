.monitor {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-bg-elevated);
    padding: var(--space-4);
    height: 100%;
    width: 100%;
    border: 2px solid var(--color-primary);
    border-radius: var(--radius-lg);
    box-shadow: 0 4px 8px oklch(0 0 0 / 0.5);
    transition: all var(--transition-normal);
    position: relative;
    overflow: hidden;
}

.monitor::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
        45deg,
        transparent 0%,
        var(--color-glass) 45%,
        var(--color-glass) 55%,
        transparent 100%
    );
    opacity: 0;
    transition: opacity var(--transition-normal);
}

.monitor:hover {
    transform: scale(1.02);
    box-shadow: var(--glow-md) var(--color-primary);
}

.monitor:hover::before {
    opacity: 0.1;
    animation: scanline 2s linear infinite;
}

.monitor-title {
    color: var(--color-primary);
    font-size: var(--font-size-xl);
    margin-bottom: var(--space-3);
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.05em;
    position: relative;
    z-index: 1;
}

.monitor-content {
    color: var(--color-text);
    text-align: center;
    margin-bottom: var(--space-3);
    line-height: 1.4;
    position: relative;
    z-index: 1;
}

.monitor-link {
    display: inline-block;
    padding: var(--space-2) var(--space-4);
    background-color: var(--color-primary);
    color: var(--color-bg);
    text-decoration: none;
    border-radius: var(--radius-md);
    transition: all var(--transition-normal);
    font-weight: 500;
    position: relative;
    z-index: 1;
}

.monitor-link:hover {
    transform: translateY(-2px);
    box-shadow: var(--glow-sm) var(--color-primary);
}

.monitor-link:active {
    transform: translateY(0);
}

@keyframes scanline {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(100%);
    }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
    .monitor:hover {
        transform: none;
    }
    
    .monitor:hover::before {
        animation: none;
    }
}
