.profile-section {
    position: fixed;
    top: var(--space-4);
    left: var(--space-4);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-3);
    background: var(--color-glass);
    padding: var(--space-3);
    border-radius: var(--radius-lg);
    border: 1px solid var(--color-primary);
    backdrop-filter: blur(8px);
    z-index: var(--z-above);
    transition: all var(--transition-normal);
}

.profile-section:hover {
    transform: translateY(2px);
    box-shadow: var(--glow-sm) var(--color-primary);
}

.financial-info {
    text-align: center;
}

.financial-info h2 {
    font-size: var(--font-size-xl);
    color: var(--color-primary);
    text-shadow: var(--glow-sm) var(--color-primary);
    margin-bottom: var(--space-2);
}

.financial-info p {
    font-size: var(--font-size-md);
    color: var(--color-text);
    opacity: 0.9;
}

.stats-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--space-2);
    width: 100%;
    margin-top: var(--space-2);
}

.stat-item {
    background: var(--color-bg);
    padding: var(--space-2);
    border-radius: var(--radius-md);
    text-align: center;
    border: 1px solid var(--color-primary);
}

.stat-label {
    font-size: var(--font-size-sm);
    color: var(--color-text);
    opacity: 0.8;
}

.stat-value {
    font-size: var(--font-size-md);
    color: var(--color-primary);
    font-weight: bold;
}

/* Loading state */
.loading-placeholder {
    background: linear-gradient(
        90deg,
        var(--color-bg) 0%,
        var(--color-glass) 50%,
        var(--color-bg) 100%
    );
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: var(--radius-md);
}

.loading-text {
    height: 1em;
    width: 80px;
}

@keyframes loading {
    0% { background-position: 200% 0; }
    100% { background-position: -200% 0; }
}

/* Responsive design */
@media (max-width: var(--breakpoint-md)) {
    .profile-section {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        margin-bottom: var(--space-4);
        flex-direction: row;
        justify-content: space-between;
    }

    .financial-info {
        text-align: left;
    }

    .stats-container {
        display: none;
    }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
    .profile-section {
        transition: none;
    }

    .profile-section:hover {
        transform: none;
    }

    .loading-placeholder {
        animation: none;
        background: var(--color-glass);
    }
}
