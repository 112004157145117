.chat-window {
    position: fixed;
    top: var(--space-4);
    left: 50%;
    transform: translateX(-50%);
    width: min(60%, var(--breakpoint-lg));
    background: var(--color-glass);
    backdrop-filter: blur(8px);
    padding: var(--space-3);
    border-radius: var(--radius-lg);
    border: 1px solid var(--color-primary);
    box-shadow: var(--glow-sm) var(--color-primary);
    z-index: var(--z-above);
}

.chat-log {
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: var(--space-3);
    padding-right: var(--space-2);
    scrollbar-width: thin;
    scrollbar-color: var(--color-primary) var(--color-bg);
}

.chat-log::-webkit-scrollbar {
    width: 6px;
}

.chat-log::-webkit-scrollbar-track {
    background: var(--color-bg);
    border-radius: var(--radius-full);
}

.chat-log::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    border-radius: var(--radius-full);
}

.chat-message {
    margin-bottom: var(--space-2);
    line-height: 1.4;
    animation: messageAppear var(--transition-normal) ease-out;
}

.chat-message strong {
    color: var(--color-primary);
}

.chat-input-container {
    position: relative;
    display: flex;
    gap: var(--space-2);
}

.chat-input {
    flex: 1;
    background-color: var(--color-bg);
    border: 1px solid var(--color-primary);
    color: var(--color-text);
    padding: var(--space-2) var(--space-3);
    border-radius: var(--radius-md);
    font-family: var(--font-main);
    transition: all var(--transition-normal);
}

.chat-input:focus {
    outline: none;
    box-shadow: var(--glow-sm) var(--color-primary);
}

.chat-input::placeholder {
    color: var(--color-text);
    opacity: 0.5;
}

.send-button {
    background-color: var(--color-primary);
    color: var(--color-bg);
    border: none;
    padding: var(--space-2) var(--space-3);
    border-radius: var(--radius-md);
    cursor: pointer;
    transition: all var(--transition-normal);
}

.send-button:hover {
    transform: scale(1.05);
    box-shadow: var(--glow-sm) var(--color-primary);
}

.send-button:active {
    transform: scale(0.95);
}

@keyframes messageAppear {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: var(--breakpoint-md)) {
    .chat-window {
        width: 90%;
        top: var(--space-3);
    }
    
    .chat-input-container {
        flex-direction: column;
    }
    
    .send-button {
        width: 100%;
    }
}
